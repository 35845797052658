import React from "react";

import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import CaseStudy from "../../components/info-site/CaseStudy";

const VitaminSupplement = props => {
    return (
        <Layout light location={props.location}>
            <Seo titleId="supplementCaseTitle" descriptionId="supplementCaseDescription" />
            <CaseStudy
              title="supplementCaseTitle"
              caseStudy="caseStudy1"
            />
        </Layout>
    );
};

export default VitaminSupplement;