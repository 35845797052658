import React from 'react'
import { FormattedMessage } from 'react-intl';

import styles from "../../styles/info-site/CaseStudy.module.css";

import caseStudy1 from '../../assets/case-studies-1.png'
import caseStudy2 from '../../assets/case-studies-2.png'
import caseStudy3 from '../../assets/case-studies-3.png'

const caseStudies = {
  caseStudy1,
  caseStudy2,
  caseStudy3
}

function CaseStudy({ title, caseStudy }) {
  return (
    <div className={styles.CaseStudy}>
      <h2 className={styles.CaseStudyTitle}><FormattedMessage id={title} /></h2>
      <img src={caseStudies[caseStudy]} alt="" width="100%" />
    </div>
  )
};

export default CaseStudy;